import { useState, useEffect } from 'react';
import { isValidUrl } from '../utils/imageUtils';

interface ImageWithFallbackProps {
  src?: string | null;
  alt?: string;
  className?: string;
  fallbackClassName?: string;
}

/**
 * A component that handles image loading with fallbacks
 * - Shows a loading state while the image is loading
 * - Handles errors gracefully
 * - Validates the URL before attempting to load
 */
function ImageWithFallback({ 
  src, 
  alt = '', 
  className = '', 
  fallbackClassName = '' 
}: ImageWithFallbackProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [validUrl, setValidUrl] = useState<boolean>(false);

  useEffect(() => {
    // Reset states when src changes
    setLoading(true);
    setError(false);
    
    // Check if the URL is valid
    if (src && isValidUrl(src)) {
      setValidUrl(true);
    } else {
      setValidUrl(false);
      setLoading(false);
    }
  }, [src]);

  const handleLoad = () => {
    setLoading(false);
  };

  const handleError = () => {
    setError(true);
    setLoading(false);
  };

  // If URL is invalid or there was an error, return null (no image)
  if (!validUrl || error) {
    return null;
  }

  return (
    <div className={`image-container ${fallbackClassName}`}>
      {loading && <div className="image-loading-placeholder"></div>}
      <img
        src={src || undefined}
        alt={alt}
        className={`${className} ${loading ? 'loading' : ''}`}
        onLoad={handleLoad}
        onError={handleError}
      />
    </div>
  );
}

export default ImageWithFallback; 
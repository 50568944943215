import { useState, useEffect } from 'react';
import Header from './components/Header';
import Column from './components/Column';
import Headline from './components/Headline';
import rawHeadlines from './data/headlines.json';
import './App.css';

// Define types for our data structure
interface HeadlineData {
  headline: string;
  url: string;
  urlToImage?: string | null; // Allow null for urlToImage
  publishedAt: string;
  importance?: number;
  [key: string]: any; // Allow for other properties
}

interface HeadlinesData {
  main: HeadlineData;
  headlines: HeadlineData[][];
}

function App() {
  // State for headlines data with error handling
  const [headlinesData, setHeadlinesData] = useState<HeadlinesData | null>(null);
  const [dataError, setDataError] = useState<boolean>(false);

  // Theme state: 'light' or 'dark'
  const [theme, setTheme] = useState<'light' | 'dark'>(() => {
    const savedTheme = localStorage.getItem('theme') as 'light' | 'dark' | null;
    if (savedTheme) {
      return savedTheme;
    }
    // Default to system preference
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  });

  // Load and validate headlines data
  useEffect(() => {
    try {
      // Check if headlines are available
      if (!rawHeadlines || !rawHeadlines.headlines || !rawHeadlines.headlines[0] || !rawHeadlines.headlines[0][0]) {
        throw new Error('Invalid headlines data structure');
      }
      
      // Process main headline
      const main = rawHeadlines.main || rawHeadlines.headlines[0][0];
      
      // Create a compatible data structure
      const processedHeadlines: HeadlinesData = {
        main: {
          ...main,
          // Ensure headline property exists
          headline: main.headline || main.title || '',
          // Safely access and process the urlToImage property
          urlToImage: ('urlToImage' in main && main.urlToImage !== undefined) ? 
                      (main.urlToImage === null ? undefined : main.urlToImage as string) : 
                      undefined
        },
        headlines: rawHeadlines.headlines.map(column => 
          column.map(headline => ({
            ...headline,
            // Ensure headline property exists
            headline: headline.headline || headline.title || '',
            // Safely access and process the urlToImage property
            urlToImage: ('urlToImage' in headline && headline.urlToImage !== undefined) ? 
                        (headline.urlToImage === null ? undefined : headline.urlToImage as string) : 
                        undefined
          }))
        )
      };
      
      // Ensure main headline has required properties
      if (!processedHeadlines.main.headline || !processedHeadlines.main.url) {
        throw new Error('Main headline missing required properties');
      }
      
      setHeadlinesData(processedHeadlines);
    } catch (error) {
      console.error('Error loading headlines data:', error);
      setDataError(true);
    }
  }, []);

  // Apply theme by setting a data attribute on the body
  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);

  // Theme toggle handler
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  // If there's an error loading data, show an error message
  if (dataError) {
    return (
      <div className="App">
        <Header />
        <div className="error-container">
          <h2>Error Loading Headlines</h2>
          <p>There was a problem loading the headlines data. Please try again later.</p>
        </div>
      </div>
    );
  }

  // If data is still loading, show a loading indicator
  if (!headlinesData) {
    return (
      <div className="App">
        <Header />
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Loading headlines...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <Header />

      <button className="theme-toggle-button" onClick={toggleTheme} aria-label="Toggle Theme">
        {theme === 'light' ? '🌙' : '☀️'}
      </button>

      <div className="main-headline-container">
        <Headline
          headline={headlinesData.main.headline}
          url={headlinesData.main.url}
          isMain={true}
          urlToImage={headlinesData.main.urlToImage}
        />
      </div>

      <div className="content">
        {headlinesData.headlines.map((col, index: number) => (
          <Column key={index}>
            {col.map(({ headline, url, urlToImage }, idx: number) => (
              <Headline 
                key={idx} 
                headline={headline} 
                url={url} 
                urlToImage={urlToImage && urlToImage !== '' ? urlToImage : undefined} 
              />
            ))}
          </Column>
        ))}
      </div>
    </div>
  );
}

export default App;

/**
 * Utility functions for handling images
 */

/**
 * Checks if an image URL is valid
 * @param url The URL to check
 * @returns A promise that resolves to true if the image is valid, false otherwise
 */
export const isImageValid = (url: string): Promise<boolean> => {
  if (!url) return Promise.resolve(false);
  
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
};

/**
 * Preloads an image to ensure it's in the browser cache
 * @param url The URL of the image to preload
 */
export const preloadImage = (url: string): void => {
  if (!url) return;
  
  const img = new Image();
  img.src = url;
};

/**
 * Checks if a URL string is a valid URL format
 * @param url The URL string to check
 * @returns True if the URL is valid, false otherwise
 */
export const isValidUrl = (url: string): boolean => {
  if (!url) return false;
  
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}; 
import ImageWithFallback from './ImageWithFallback';
import './Headline.css';

interface HeadlineProps {
  headline: string;
  url: string;
  isMain?: boolean;
  urlToImage?: string | null;
}

function Headline({ headline, url, isMain = false, urlToImage }: HeadlineProps) {
  const hasImage = !!urlToImage;

  return (
    <div className={isMain ? 'main-headline headline' : 'headline'}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <ImageWithFallback 
          src={urlToImage || undefined} 
          alt="" 
          className="headline-image" 
        />
        <div className={`headline-text ${!hasImage ? 'no-image' : ''}`}>
          {headline}
        </div>
      </a>
    </div>
  );
}

export default Headline;
